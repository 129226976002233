/* ==========================================================================
Main SCSS file / Fresh
========================================================================== */

@import 'abstracts/colors';
@import 'abstracts/animations';
@import 'layout/sidebar';
@import 'layout/sections';
@import 'layout/hero';
@import 'layout/footer';
@import 'components/buttons';
@import 'components/dropdowns';
@import 'components/cards';
@import 'components/forms';
@import 'components/testimonials';
@import 'utilities/utils';
@import 'layout/responsive';
@import 'extensions/bulma-tooltip.css';

.hero-body, .testimonials {
    background-color: rgba(255, 255, 255, 0.9);
  }

.hero-body .subtitle {
  margin-bottom: 6rem;
}

.hero-cta {
  margin-top: auto;
}
.notification.is-primary {
  background-color: rgba(0,229,92,0.95);
}
.hero-body .title, .hero-body .subtitle {
  color: #{$hero} !important;
}

.card-icon ion-icon {
  display: block;
  font-size: 64px;
  margin: 30px auto;
}
div.feature .title {
  display: flex;

  ion-icon {
    padding-right: 0.5em;
  }
}

.navbar-item img {
	max-height: 2rem;
}

.feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.feather-card {
  width: 64px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.feather-card-price {
  width: 90px;
  stroke: currentColor;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.feather-social {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: currentColor;
}

.feather-attribute {
  width: 120px;
  stroke: currentColor;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.feature-card {
  max-width: 360px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}
.feature-card {
  .card-text {
    padding: 0;
    margin-bottom: 2rem;
    text-align: left;
  }
  .card-action {
    margin-top: auto;
    margin-bottom: 2rem;
  }
  .card-title h4 {
    font-size: 2rem;
  }
}
