/* ==========================================================================
Section Styles
========================================================================== */

//Sections
.section {
    position: relative;
    overflow-x: hidden !important;

    &:focus {
        outline: none !important;
    }
    &.section-primary {
        background-color: $primary;
    }
    &.section-light-grey {
        background-color: $light-grey;
    }

    &.section-feature-grey {
        background-color: $section-grey;
    }

    &.section-secondary {
        background-color: $secondary;
    }

    &.section-half {
        height: 75vh !important;
    }

    &.has-background-image {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .title, .subtitle {
        font-family: 'Open Sans', sans-serif;
    }

    .subtitle {
        &.is-muted {
            color: $muted-grey;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
    }
}

//Titles
.title-wrapper {
    max-width: 500px;
    margin: 0 auto;

    .title, .subtitle {
        font-family: 'Open Sans', sans-serif;
    }

    .subtitle {
        &.is-muted {
            color: $muted-grey;
        }
    }
}

//Divider
.divider {
    height: 2px;
    border-radius: 50px;
    background: $secondary;
    width: 100px;

    &.is-centered {
        margin: 0 auto;
    }
}

//Wrapper
.content-wrapper {
    padding: 60px 0;
}

//Side feqtures
.side-feature {
    h3 {
        padding-bottom: 16px;
    }
}

//Icon box
.feature-icon {
    padding: 16px 0;
    cursor: pointer;

    &:hover {
        .icon-wrap {
            transform: translateY(-5px);
            box-shadow: $light-shadow;

            ion-icon {
                color: $secondary;
            }
        }
    }

    .icon-wrap {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        border: 1px solid darken($fade-grey, 4%);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 10px auto;
        transition: all .3s;

        ion-icon {
            font-size: 110px;
            color: lighten($blue-grey, 18%);
            transition: all .3s;
        }
    }

    h4 {
        font-family: $font;
        font-weight: 600;
        color: $blue-grey;
    }

    p {
        color: $muted-grey;
    }
}

//Pricing
.pricing-wrap {
    display: flex;
    justify-content: center;
}

.feature-card {
    &.is-pricing {
        border: 1px solid darken($fade-grey, 4%);
        border-radius: 6px;
        box-shadow: $light-shadow;
        margin: 8px;
        padding: 20px;
        height: 430px;

        .plan-name {
            font-family: $font;
            font-weight: 600;
            font-size: 1.4rem;
            color: $blue-grey;
        }

        img {
            display: block;
            max-width: 160px;
            margin: 0 auto 0 auto;
        }

        .price:lang(en) {
            position: relative;
            font-family: $font;
            font-weight: 700;
            font-size: 2.4rem;
            color: $blue-grey;
            margin-bottom: 30px;
            &:before {
                content: '€';
                position: relative;
                top: -12px;
                left: 5px;
                font-size: 1.2rem;
            }

            &:after {
                content: 'per month, excl. vat';
                position: absolute;
                bottom: -16px;
                left: 0;
                right: 0;
                margin: 0 auto;
                font-size: .9rem;
                font-weight: 400;
                color: $muted-grey;
            }

        }
        .price:lang(de) {
            position: relative;
            font-family: $font;
            font-weight: 700;
            font-size: 2.4rem;
            color: $blue-grey;
            margin-bottom: 30px;
            &:before {
                content: '€';
                position: relative;
                top: -12px;
                left: 5px;
                font-size: 1.2rem;
            }

            &:after{
                content: 'pro Monat, zzgl. MwSt';
                position: absolute;
                bottom: -16px;
                left: 0;
                right: 0;
                margin: 0 auto;
                font-size: .9rem;
                font-weight: 400;
                color: $muted-grey;
            }
        }
        .price:lang(ee) {
            position: relative;
            font-family: $font;
            font-weight: 700;
            font-size: 2.4rem;
            color: $blue-grey;
            margin-bottom: 30px;
            &:before {
                content: '€';
                position: relative;
                top: -12px;
                left: 5px;
                font-size: 1.2rem;
            }

            &:after {
                content: 'kuu, v.a. käibemaks';
                position: absolute;
                bottom: -16px;
                left: 0;
                right: 0;
                margin: 0 auto;
                font-size: .9rem;
                font-weight: 400;
                color: $muted-grey;
            }

        }
        p {
            padding: 24px 0 16px 0;
            color: $muted-grey;
        }
    }
}
