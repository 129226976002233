body {
    overflow-x: hidden;
}

//Back to top button
#backtotop {
    position: fixed;
    right: 0;
    opacity: 0;
    visibility: hidden;
    bottom: 25px;
    margin: 0 25px 0 0;
    z-index: 9999;
    transform: scale(0.7);
    transition: all 0.5s;

    &.visible {
        opacity: 1;
        visibility: visible;
        transform: scale(1);

        a {
            padding-top: 6px;
            outline: none;
            text-decoration: none;
            border: 0 none;
            display: block;
            width: 46px;
            height: 46px;
            background-color: $medium-grey;
            color: $white;
            opacity: 1;
            transition: all 0.5s;
            border-radius: 50%;
            text-align: center;
            font-size: 26px;

            &:hover {
                outline: none;
                opacity: 0.9;
                background: $secondary;
            }
        }
    }
}


//Helpers
.is-disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: default !important;
}

.is-hidden {
    display: none !important;
}

.stuck {
    position: fixed !important;
    top: 0 !important;
    z-index: 2 !important;
}

.light-text {
    color: $white !important;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-60 {
    margin-top: 60px;
}

.ml-30 {
    margin-left: 30px;
}

.huge-pb {
    padding-bottom: 100px;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

//Input placeholders
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholder;
}

::-moz-placeholder { /* Firefox 19+ */
    color: $placeholder;
}

:-ms-input-placeholder { /* IE 10+ */
    color: $placeholder;
}

:-moz-placeholder { /* Firefox 18- */
    color: $placeholder;
}
