/* ==========================================================================
Color variables
========================================================================== */

$fullhd-enabled: false;
$body-size: 14px;

$white: #fff;
$smoke-white: #fcfcfc;
$grey-white: #f2f2f2;

$primary: rgba(0,229,92,1);
$secondary: #73B9C4;
$accent: #9F574D;
$hero: #2D474B;
$fade-grey: #ededed;
$light-grey: #EFF4F7;
$title-grey: #A9ABAC;
$blue-grey: #444F60;
$muted-grey: #999;
$light-blue-grey: #98a9c3;
$medium-grey: #66676b;
$basaltic-grey: #878787;
$purple: #7F00FF;
$mint: #11FFBD;
$bloody: #FC354C;
$pinky: #ff00cc;
$frost: #004e92;
$placeholder: #cecece;
$dark-grey: #344258;
$border-grey: #ccc;
$muted-grey: #999;
$section-grey: #fbfbfb;

//Heading font
$font: 'Montserrat', sans-serif !important;

//Shadow
$light-shadow: -1px 3px 15px 0 rgba(0, 0, 0, 0.06);
